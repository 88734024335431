/**
 * Const with params element
 * Video component
 * Display an iframe of a video
 */
/* Import section */
import React from "react"

/* Declaration function */
const Video = ({ videoSrcURL, videoTitle}) => {
  return (
    <div className="video-foreground">
      <iframe
        src={`${videoSrcURL}?version=3&rel=0&enablejsapi=1`}
        title={videoTitle}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        className="video-iframe"
      />
    </div>
  )
}

/* Export function */
export default Video
