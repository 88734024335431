/**
 * Class element
 * Block Learn Video component
 * Display a block of video information with CTA of video in lightbox and description in accordeon
 */
/* Import section */
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { FiPlus, FiX } from "react-icons/fi"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import Video from "./video"
import Iconplayvideo from "../images/icons/iconplayvideo.inline.svg"
import "../styles/common/lightboxvideo.scss"
import "../styles/components/blocklearnvideo.scss"

/* Declaration class */
class Blocklearnvideo extends Component {

  targetElement = null
  video = null

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      showLightbox: false
    }
  }

  componentDidMount() {
    var indexVideo = this.props.index
    this.targetElement = document.querySelector('#videoLightbox-'+indexVideo);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  manageLightbox(boolStateLightbox){
    this.setState({ showLightbox: boolStateLightbox })

    if(boolStateLightbox === true) {
      var indexVideo = this.props.index
      this.video = document.querySelector( '#videoLightbox-'+indexVideo+' .video-foreground .video-iframe' );
      disableBodyScroll(this.targetElement);
      this.video.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), '*');
    }else{
      enableBodyScroll(this.targetElement);
      this.video.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*');
    }
  }

  toggleDescription() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render () {
    var classOpen = this.state.isOpen ? "open" : ""
    var classNameLightbox=(this.state.showLightbox === true) ? "":"hidden"
    var indexVideo = this.props.index

    return (
      <div id={ `block-learnvideo-${indexVideo}` } className="block-learnvideo block-video">
        <div className="title-cta-video flex-content" onClick={() => this.manageLightbox(true)} onKeyDown={() => this.manageLightbox(true)} role="button" tabIndex={0}>
          <h3 className="title-level-3">{this.props.titleVideo}</h3>
          <button onClick={() => this.manageLightbox(true)} onKeyDown={() => this.manageLightbox(true)} aria-label="Watch the Video"><span><Iconplayvideo /></span></button>
        </div>
        <p className="little-p">{this.props.timeVideo}</p>
        <div className="description-video">
          <div className="header-description-video flex-content" onClick={() => this.toggleDescription()} onKeyDown={() => this.toggleDescription()} role="button" tabIndex={0}>
            <IconContext.Provider value={{ className: "icon-toggle" }}>
              <span className={ classOpen }>
                <FiPlus />
              </span>
            </IconContext.Provider>
            <h4 className={ `title-level-3 ${ classOpen }` }>Video description</h4>
          </div>
          <div className={ `content-description-video ${ classOpen }` } dangerouslySetInnerHTML={{ __html:this.props.descriptionVideo}} />
        </div>
        <div id={`videoLightbox-${indexVideo}`} className={`video-lightbox ${classNameLightbox}`} onClick={() => this.manageLightbox(false)}  onKeyDown={() => this.manageLightbox(false)} role="button" tabIndex={0} aria-label="Close the lightbox">
          <button className="button-close-lightbox" onClick={() => this.manageLightbox(false)} onKeyDown={() => this.manageLightbox(false)} aria-label="Close the lightbox">
              <IconContext.Provider value={{ className: "icon-closed-lightbox" }}>
                <i aria-hidden="true"><FiX /></i>
              </IconContext.Provider>
          </button>
          <Video videoSrcURL={this.props.linkVideo} videoTitle={this.props.titleVideo} />
        </div>
      </div>
    )
  }
}

/* Export function */
export default Blocklearnvideo
