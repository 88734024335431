/**
 * Learning Center page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Blocklearnvideo from "../components/blocklearnvideo"
import Hero from "../components/hero"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Sectionh1content from "../components/sectionh1content"
import Seo from "../components/seo"
import "../styles/pages/learning-center.scss"

/* Page function declaration */
const LearningcenterPage = ({ data }) => {
  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageLearningcenter

  //CTA Footer page CONTACT
  var ctaFooterContact = data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
    return ctaFooter.typeCtaFooter === "contact"
  })

  return (
    <Layout className="learning-center">
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Section Hero */}
      <Hero colorShape="bluegreyless" classNamePage="learning-center" />

      {/* Section H1 */}
      <Sectionh1content titleH1={dataAcf.titleGeneralLearn} contentH1={dataAcf.contentGeneralLearn} />

      {/* Main content */}
      <section className="main-section bluegreyless" >
        <div className="content-general">
          <h2 className="title-level-2">{dataAcf.titleSectionVideo}</h2>
          <div className="list-video flex-content">
            {dataAcf.repeaterVideosLearn.map( (videolearn, index) => (
              <Blocklearnvideo
                key={ `block-learnvideo-${index}` }
                index = {index}
                titleVideo={videolearn.titleVideoLearn}
                timeVideo={videolearn.timeVideoLearn}
                descriptionVideo={videolearn.descriptionVideoLearn}
                linkVideo={videolearn.linkVideoLearn}
            />
            ))}
          </div>
        </div>
      </section>

      {/* Section CtaFooter */}
     {ctaFooterContact.map(function (ctafooter, i) {
        return <Sectionctafooter key={i} typeCta="contact" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegreyless" />
      })}

    </Layout>
  )
}
/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "learning-center"}) {
      acfPageLearningcenter {
        titleGeneralLearn
        contentGeneralLearn
        titleSectionVideo
        repeaterVideosLearn {
          titleVideoLearn
          timeVideoLearn
          descriptionVideoLearn
          linkVideoLearn
        }
      }
      seo {
        metaDesc
        title
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
/* Export page informations */
export default LearningcenterPage
